import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { c_opt_position2, locale_opt } from "../../common/Commons";
import './styles.css';


const TradingView_Test = ({type,result_file_path , strategy_name,exchange_name,coin_type,time_frame, max_pair_count, current_pair_count, max_pair_index,start_trade_time}) => {
  
  const chartContainerRef = useRef();
  const chart = useRef();

  var candleSeries = []
  var volumeSeries = []
  var verticalLineSeries = []
  const resizeObserver = useRef();

  
  const [start_trade_time_local, set_start_trade_time_local] = useState("");
  const [current_candle_data, set_current_candle_data] = useState([]);
  
  const [dateFrom, setDateFrom] = useState("all");
  const [dateTo, setDateTo] = useState("all");
  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const [asset, setAsset] = useState({})
  const [profitText, setProfitText] = useState("")
  const [strategyName, setstrategyName] = useState(strategy_name.toUpperCase() + "_" +  exchange_name.toUpperCase() + "_" + time_frame + "_" + coin_type.toUpperCase() + "USDT")
  const [reflesh, set_reflesh] = useState(1)

  
  const [position_time, set_position_time] = useState("");
  const [position, setPosition] = useState("");
  const opt_position = c_opt_position2

  var current_candle_data_length = 0

  function get_asset() {
    //console.log("get_asset ! ")
    //const python_server_url = "https://aitrading.lukiumedia.com"
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
    const data = {
      start_trade_time : start_trade_time,
      result_file_path: result_file_path,
      max_pair_count: max_pair_count,
      current_pair_count: current_pair_count,
      max_pair_index: max_pair_index
    };
    //console.log("get_asset , result_file_path ===> " + result_file_path)
    //console.log(python_server_url)
    //console.log("data ===> ")
    //console.log(JSON.stringify(data))

    axios
      .post(`${python_server_url}/get_backtesting_asset`, data)
      .then((res) => {
        //console.log(JSON.stringify(res.data))
        //alert(JSON.stringify(res.data))
        if (res.data.status == "ok") {
          //console.log("recevice ! ,  getCandleData !")
          //console.log("get_asset, respoinse : " + JSON.stringify(res.data.data))

          setAsset(res.data.data)

          var temp_date = new Date(parseInt(res.data.data.backtesting_from_date));
          var temp_month = parseInt(temp_date.getMonth()) + 1

          var temp_month_init = temp_month
          var temp_month_str = ""
          if (temp_month_init < 10) {
            temp_month_str = "0" + temp_month_init.toString()
          } else {
            temp_month_str = temp_month_init.toString()
          }

          var temp_date_init = temp_date.getDate()
          var temp_date_init_str = ""
          if (temp_date_init < 10) {
            temp_date_init_str = "0" + temp_date_init.toString()
          } else {
            temp_date_init_str = temp_date_init.toString()
          }

          var temp_full_date = temp_date.getFullYear() + "-" + temp_month_str + "-" + temp_date_init_str
          setDateFrom(temp_full_date)
          //console.log("temp_full_date_from ==> " + temp_full_date)

          var temp_minit_init = parseInt(temp_date.getMinutes())
          var temp_minit_str = ""
          if (temp_minit_init < 10) {
            temp_minit_str = "0" + temp_minit_init.toString()
          } else {
            temp_minit_str = temp_minit_init.toString()
          }

          var temp_tome_init = parseInt(temp_date.getHours())
          var temp_time_str = ""
          if (temp_tome_init < 10) {
            temp_time_str = "0" + temp_tome_init.toString()
          } else {
            temp_time_str = temp_tome_init.toString()
          }

          var temp_full_time = temp_time_str + ":" + temp_minit_str
          setTimeFrom(temp_full_time)
          //console.log("temp_full_time_from ==> " + temp_full_time)

          ///////////////////////////////////////////////////

          var temp_date_to = new Date(parseInt(res.data.data.backtesting_to_date));
          //console.log("temp_date_to ==> " + temp_date_to)
          var temp_month_to = parseInt(temp_date_to.getMonth()) + 1

          var temp_month_init_to = temp_month_to
          var temp_month_str_to = ""
          if (temp_month_init_to < 10) {
            temp_month_str_to = "0" + temp_month_init_to.toString()
          } else {
            temp_month_str_to = temp_month_init_to.toString()
          }


          var temp_date_init_to = temp_date_to.getDate()
          var temp_date_init_str_to = ""
          if (temp_date_init_to < 10) {
            temp_date_init_str_to = "0" + temp_date_init_to.toString()
          } else {
            temp_date_init_str_to = temp_date_init_to.toString()
          }

          var temp_full_date_to = temp_date_to.getFullYear() + "-" + temp_month_str_to + "-" + temp_date_init_str_to
          setDateTo(temp_full_date_to)

          //console.log("temp_full_date_to ==> " + temp_full_date_to)

          var temp_minit_init_to = parseInt(temp_date_to.getMinutes())
          var temp_minit_str_to = ""
          if (temp_minit_init_to < 10) {
            temp_minit_str_to = "0" + temp_minit_init_to.toString()
          } else {
            temp_minit_str_to = temp_minit_init_to.toString()
          }

          var temp_tome_init_to = parseInt(temp_date_to.getHours())
          var temp_time_str_to = ""
          if (temp_tome_init_to < 10) {
            temp_time_str_to = "0" + temp_tome_init_to.toString()
          } else {
            temp_time_str_to = temp_tome_init_to.toString()
          }


          var temp_full_time_to = temp_time_str_to + ":" + temp_minit_str_to
          setTimeTo(temp_full_time_to)
          //console.log("temp_full_time_to ==> " + temp_full_time_to)


        } else {
          setAsset({})
        }
      })
      .catch((err) => {
        //alert(err);
        console.log(err);
      });
  }


  function get_candle_realtime() {

    //console.log("get_candle_realtime ! ")
    const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
    const data = {
      result_file_path: result_file_path,
      max_pair_index: max_pair_index,
      exchange_name : exchange_name,
      coin_type : coin_type ,
      time_frame : time_frame
    };
    //console.log("get_candle_realtime , result_file_path ===> " + result_file_path)
    //console.log(python_server_url)
    //console.log("data ===> ")
    //console.log(JSON.stringify(data))

    //var turl = "get_candle_backtesting_result_array"
    var turl = "get_backtesting_chart"

    axios
      .post(`${python_server_url}/${turl}`, data)
      .then((res) => {
        //console.log(JSON.stringify(res.data))
        //alert(JSON.stringify(res.data))
        if (res.data.status == "ok") {

          //console.log("current_candle_data_length ==> " + current_candle_data_length)
          //console.log("res.data.data.length ==> " + res.data.data.length)
          if (current_candle_data_length ==  0 ){
            
            //console.log("make_candle_Data")
            current_candle_data_length = res.data.data.length
            make_candle_Data(res.data.data)

          }else {
            if (res.data.data.length >  current_candle_data.length ){
              //console.log("update_candle_Data")
              current_candle_data_length = res.data.data.length
              update_candle_Data(res.data.data)
            }
          }
        }
      })
      .catch((err) => {
        //alert(err);
        console.log(err);
      });
  }


  function make_candle_Data(candle_data) {

    //////////////////////////////////////////////////////////////////////
    // 1. price data & volume 
    var temp_kandle_data = []
    var temp_volume_data = []
    if (candle_data.length > 0) {
      candle_data.forEach((tempdata, index) => {
        let temp_json = {
          // time: tempdata['opentime']/1000,
          time: tempdata['opentime'] / 1000 - (3600 * 7),
          open: tempdata['open'],
          high: tempdata['high'],
          low: tempdata['low'],
          close: tempdata['close'],
          short: tempdata['short_entry'],
          long: tempdata['long_entry'],
          short_close: tempdata['short_close'],
          long_close: tempdata['long_close'],
          short_exit: tempdata['short_exit'],
          long_exit: tempdata['long_exit'],
          realtime_start: tempdata['realtime_start'],
        }
        temp_kandle_data.push(temp_json)

        let temp_volume_json = {
          // time: tempdata['opentime']/1000,
          time: tempdata['opentime'] / 1000 - (3600 * 7),
          volume: tempdata['volume'],
        }
        temp_volume_data.push(temp_volume_json)

      });
      //////////////////////////////////////////////////////////////////////
      
      //////////////////////////////////////////////////////////////////////
      //2. mark 
      var temp_marker_data = []
      temp_kandle_data.forEach((tempdata, index) => {

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // live
        if (type =='live'){

            // 색상 지정
            var short_color = "#e91e63"
            var long_color = "#2196F3"
            if (tempdata['time'] <  ((parseInt(start_trade_time)  / 1000) - (3600 * 7) )) {
              short_color = "#641E16"
              long_color = "#154360"
            }

            // 실제 거래 시작점 표시함
            if (tempdata['time'] ==  ((parseInt(start_trade_time)  / 1000) - (3600 * 7) )){
              var temp_json = {
                text: 'Starting Point',
                time: tempdata['time'],
                position: 'belowBar',
                color: '#FFEB3B',
                shape: 'arrowUp',
                size: 2,
              }
              //console.log("LONG : " + JSON.stringify(temp_json))
              temp_marker_data.push(temp_json)
            }
            ///////////////////////////////////////////////////////////
            // long
            if (tempdata['long'].toString().toUpperCase() == 'TRUE') {

                if (tempdata['long_exit'].toString().toUpperCase() == 'SL') {
                      var temp_json = {
                      text: 'Long Open(S/L)' + "(" + tempdata['close'] + ")",
                      time: tempdata['time'],
                      position: 'belowBar',
                      color: long_color,
                      shape: 'arrowUp',
                      size: 1,
                      }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)

                }
                else if (tempdata['long_exit'].toString().toUpperCase() == 'RESET') {
                    var temp_json = {
                      text: 'Long Open(Reset)' + "(" + tempdata['close'] + ")",
                      time: tempdata['time'],
                      position: 'belowBar',
                      color: long_color,
                      shape: 'arrowUp',
                      size: 1,
                      }
                    //console.log("short : " + JSON.stringify(temp_json))
                    temp_marker_data.push(temp_json)

                }
                else{
                  
                    var temp_json = {
                    text: 'Long Open' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'belowBar',
                    color: long_color,
                    shape: 'arrowUp',
                    size: 1,
                    }
                    //console.log("LONG : " + JSON.stringify(temp_json))
                    temp_marker_data.push(temp_json)

                }

            }
            // long close
            if (tempdata['long_close'].toString().toUpperCase() == 'TRUE') {

              if (tempdata['long_exit'].toString().toUpperCase() == 'SL') {
                    var temp_json = {
                    text: 'Long S/L' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'belowBar',
                    color: long_color,
                    shape: 'arrowUp',
                    size: 1,
                    }
                //console.log("short : " + JSON.stringify(temp_json))
                temp_marker_data.push(temp_json)

              }
              else if (tempdata['long_exit'].toString().toUpperCase() == 'RESET') {
                  var temp_json = {
                    text: 'Long Close(Reset)' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'belowBar',
                    color: long_color,
                    shape: 'arrowUp',
                    size: 1,
                    }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)

              }
              else{
                  /*
                  var temp_json = {
                  text: 'Long Close' + "(" + tempdata['close'] + ")",
                  time: tempdata['time'],
                  position: 'belowBar',
                  color: long_color,
                  shape: 'arrowUp',
                  size: 1,
                  }
                  //console.log("LONG : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)
                  */

              }

            }
            ///////////////////////////////////////////////////////////



            ///////////////////////////////////////////////////////////
            // short
            if (tempdata['short'].toString().toUpperCase() == 'TRUE') {

                if (tempdata['short_exit'].toString().toUpperCase() == 'SL') {

                    var temp_json = {
                      text: 'Short Open(S/L)' + "(" + tempdata['close'] + ")",
                      time: tempdata['time'],
                      position: 'aboveBar',
                      color: short_color,
                      shape: 'arrowDown',
                      size: 1,
                    }
                    //console.log("short : " + JSON.stringify(temp_json))
                    temp_marker_data.push(temp_json)

                }
                else if (tempdata['short_close'].toString().toUpperCase() == 'RESET') {
                    var temp_json = {
                      text: 'Short Open(Reset)' + "(" + tempdata['close'] + ")",
                      time: tempdata['time'],
                      position: 'aboveBar',
                      color: short_color,
                      shape: 'arrowDown',
                      size: 1,
                    }
                    //console.log("short : " + JSON.stringify(temp_json))
                    temp_marker_data.push(temp_json)
                }
                else{
                    var temp_json = {
                      text: 'Short Open' + "(" + tempdata['close'] + ")",
                      time: tempdata['time'],
                      position: 'aboveBar',
                      color: short_color,
                      shape: 'arrowDown',
                      size: 1,
                    }
                    //console.log("short : " + JSON.stringify(temp_json))
                    temp_marker_data.push(temp_json)
                }
            }
            // short close
            if (tempdata['short_close'].toString().toUpperCase() == 'TRUE') {

              if (tempdata['short_exit'].toString().toUpperCase() == 'SL') {

                  var temp_json = {
                    text: 'Short S/L' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'aboveBar',
                    color: short_color,
                    shape: 'arrowDown',
                    size: 1,
                  }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)

              }
              else if (tempdata['short_close'].toString().toUpperCase() == 'RESET') {
                  var temp_json = {
                    text: 'Short Close(Reset)' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'aboveBar',
                    color: short_color,
                    shape: 'arrowDown',
                    size: 1,
                  }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)
              }
              else{
                /*
                  var temp_json = {
                    text: 'Short Close' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'aboveBar',
                    color: short_color,
                    shape: 'arrowDown',
                    size: 1,
                  }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)
                  */
              }

          }
          ///////////////////////////////////////////////////////////
          
        } // end live
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // optimize
        else{

          // 색상 지정
          var short_color = "#e91e63"
          var long_color = "#2196F3"


          ///////////////////////////////////////////////////////////
          // long
          if (tempdata['long'].toString().toUpperCase() == 'TRUE') {

              if (tempdata['long_exit'].toString().toUpperCase() == 'SL') {
                    var temp_json = {
                    text: 'Long Open(S/L)' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'belowBar',
                    color: long_color,
                    shape: 'arrowUp',
                    size: 1,
                    }
                //console.log("short : " + JSON.stringify(temp_json))
                temp_marker_data.push(temp_json)

              }
              else if (tempdata['long_exit'].toString().toUpperCase() == 'RESET') {
                  var temp_json = {
                    text: 'Long Open(Reset)' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'belowBar',
                    color: long_color,
                    shape: 'arrowUp',
                    size: 1,
                    }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)

              }
              else{
                
                  var temp_json = {
                  text: 'Long Open' + "(" + tempdata['close'] + ")",
                  time: tempdata['time'],
                  position: 'belowBar',
                  color: long_color,
                  shape: 'arrowUp',
                  size: 1,
                  }
                  //console.log("LONG : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)

              }

          }

          // long close
          if (tempdata['long_close'].toString().toUpperCase() == 'TRUE') {
            
            if (tempdata['long_exit'].toString().toUpperCase() == 'SL') {
              var temp_json = {
              text: 'Long Close(S/L)' + "(" + tempdata['close'] + ")",
              time: tempdata['time'],
              position: 'belowBar',
              color: long_color,
              shape: 'arrowUp',
              size: 1,
              }
          //console.log("short : " + JSON.stringify(temp_json))
          temp_marker_data.push(temp_json)

            }
            else if (tempdata['long_exit'].toString().toUpperCase() == 'RESET') {
                var temp_json = {
                  text: 'Long Close(Reset)' + "(" + tempdata['close'] + ")",
                  time: tempdata['time'],
                  position: 'belowBar',
                  color: long_color,
                  shape: 'arrowUp',
                  size: 1,
                  }
                //console.log("short : " + JSON.stringify(temp_json))
                temp_marker_data.push(temp_json)

            }
            else{
              /*
                var temp_json = {
                text: 'Long Close' + "(" + tempdata['close'] + ")",
                time: tempdata['time'],
                position: 'belowBar',
                color: long_color,
                shape: 'arrowUp',
                size: 1,
                }
                //console.log("LONG : " + JSON.stringify(temp_json))
                temp_marker_data.push(temp_json)
                */

            }

         }
         ///////////////////////////////////////////////////////////
       

          ///////////////////////////////////////////////////////////
          // short
          if (tempdata['short'].toString().toUpperCase() == 'TRUE') {

              if (tempdata['short_exit'].toString().toUpperCase() == 'SL') {

                  var temp_json = {
                    text: 'Short Open(S/L)' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'aboveBar',
                    color: short_color,
                    shape: 'arrowDown',
                    size: 1,
                  }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)

              }
              else if (tempdata['short_exit'].toString().toUpperCase() == 'RESET') {
                  var temp_json = {
                    text: 'Short Open(Reset)' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'aboveBar',
                    color: short_color,
                    shape: 'arrowDown',
                    size: 1,
                  }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)
              }
              else{
                  var temp_json = {
                    text: 'Short Open' + "(" + tempdata['close'] + ")",
                    time: tempdata['time'],
                    position: 'aboveBar',
                    color: short_color,
                    shape: 'arrowDown',
                    size: 1,
                  }
                  //console.log("short : " + JSON.stringify(temp_json))
                  temp_marker_data.push(temp_json)
              }

          }

          // short exit
          if (tempdata['short_close'].toString().toUpperCase() == 'TRUE') {

            if (tempdata['short_exit'].toString().toUpperCase() == 'SL') {

              var temp_json = {
                text: 'Short Close(S/L)' + "(" + tempdata['close'] + ")",
                time: tempdata['time'],
                position: 'aboveBar',
                color: short_color,
                shape: 'arrowDown',
                size: 1,
              }
              //console.log("short : " + JSON.stringify(temp_json))
              temp_marker_data.push(temp_json)

          }
          else if (tempdata['short_exit'].toString().toUpperCase() == 'RESET') {
              var temp_json = {
                text: 'Short Close(Reset)' + "(" + tempdata['close'] + ")",
                time: tempdata['time'],
                position: 'aboveBar',
                color: short_color,
                shape: 'arrowDown',
                size: 1,
              }
              //console.log("short : " + JSON.stringify(temp_json))
              temp_marker_data.push(temp_json)
          }
          else{

              /*
              var temp_json = {
                text: 'Short Close' + "(" + tempdata['close'] + ")",
                time: tempdata['time'],
                position: 'aboveBar',
                color: short_color,
                shape: 'arrowDown',
                size: 1,
              }
              //console.log("short : " + JSON.stringify(temp_json))
              temp_marker_data.push(temp_json)
              */
          }

        }// end short
        ///////////////////////////////////////////////////////////
          
        }  // end opti

      }); // end for

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
        
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///4. display chart
      if (chart) {

        // candle data
        candleSeries = chart.current.addCandlestickSeries({
          priceLineVisible: true,
          lastValueVisible: true,
          upColor: '#089981',
          downColor: '#F23645',
          borderDownColor: '#F23645',
          borderUpColor: '#089981',
          wickDownColor: '#F23645',
          wickUpColor: '#089981',
        });
        candleSeries.setData(temp_kandle_data);
        candleSeries.setMarkers(temp_marker_data);

        
        // 세로선 추가 방법 
        /*
        const lineSeries = chart.current.addLineSeries({
          lastValueVisible : false,
          priceLineVisible: false, // false
          priceScaleId: 'lines',
          priceLineWidth :0.1,
          priceLineColor : '#000033',

          priceRange: {
            minValue: 0,
            maxValue: 0,
        },
          scaleMargins: {
              top: 0.0,
              bottom: 0.0,
            }
        });
        lineSeries.setData([
          // 타임을 다르게하여 사선 형태
          {
            value: 0,
            time: (parseInt(start_trade_time) / 1000) - (3600 * 7),
          },
          {
            value: 1,
            time: ((parseInt(start_trade_time)+10)  / 1000) - (3600 * 7),
          },
        ]);
        */

        /*
        // 볼륨 추가 방법 
        volumeSeries = chart.current.addHistogramSeries({
          color: '#26a69a',
          priceFormat: {
            type: 'volume',
          },
          priceScaleId: '',
        });
        
        chart.current.priceScale('').applyOptions({
          scaleMargins: {
            top: 0.8,
            bottom: 0,
          },
        });
        volumeSeries.setData(temp_volume_data)


        */
        // resize 
        chart.current.timeScale().fitContent() // 차트 간격 조절
        //window.addEventListener("resize", () => {
        //  chart.resize(window.innerWidth, window.innerHeight);
        //});
      }

    }

  }

  function update_candle_Data(candle_data) {

    //price
    const last_candle_data = candle_data[candle_data.length - 1];
    //console.log("update_candle_Data , last_candle_data ==>" + JSON.stringify(last_candle_data))
    var temp_json = {
      // time: tempdata['opentime']/1000,
      time: last_candle_data['opentime'] / 1000 - (3600 * 7),
      open: last_candle_data['open'],
      high: last_candle_data['high'],
      low: last_candle_data['low'],
      close: last_candle_data['close'],
      short: last_candle_data['short_entry'],
      long: last_candle_data['long_entry'],
    }

    candleSeries.update(temp_json)

    /*
    var temp_json_volume = {
      // time: tempdata['opentime']/1000,
      time: last_candle_data['opentime'] / 1000 - (3600 * 7),
      volume: last_candle_data['volume'],
    }
    volumeSeries.update(temp_json_volume)
    */

    if (last_candle_data['long']) {
      var temp_json = {
        text: 'LONG' + "(" + last_candle_data['close'] + ")",
        time: last_candle_data['time'],
        position: 'belowBar',
        color: '#2196F3',
        shape: 'arrowUp',
        size: 1,
      }
      //console.log("LONG : " + JSON.stringify(temp_json))

      candleSeries.setMarkers(temp_json)
    } 
    else if (last_candle_data['short']) {

      var temp_json = {
        text: 'SHORT' + "(" + last_candle_data['close'] + ")",
        time: last_candle_data['time'],
        position: 'aboveBar',
        color: '#e91e63',
        shape: 'arrowDown',
        size: 1,
      }
      candleSeries.setMarkers(temp_json)

    }
    

}



function init_chart() {

  chart.current = createChart(chartContainerRef.current, {
    width: chartContainerRef.current.clientWidth,
    //height: chartContainerRef.current.clientHeight,

    //width: 1500,
    height: 500,

    /*
    localization: {
      timeFormatter: businessDayOrTimestamp => {
          return Date(businessDayOrTimestamp);
          // return businessDayOrTimestamp; // epock time
          //return 'bd=' + businessDayOrTimestamp.day + '-' + businessDayOrTimestamp.month + '-' + businessDayOrTimestamp.year;
         
        
        },
      },
    */

    layout: {
      background: {
        color: '#131A2B'
      },
      textColor: '#ffffff',
    },
    grid: {
      vertLines: {
        color: '#28375E',
      },
      horzLines: {
        color: '#28375E',
      },
    },
    crosshair: {
      mode: CrosshairMode.Normal,
    },
    priceScale: {
      borderColor: '#485c7b',
    },
    timeScale: {
      borderColor: '#485c7b',
      timeVisible: true,
      secondsVisible: true,
      enabled_features: ["two_character_bar_marks_labels"],
      supports_marks: true
    },
  });
  chart.current.applyOptions({
    watermark: {
      visible: true,
      fontSize: 24,
      horzAlign: 'center',
      vertAlign: 'center',
      color: '#233051',
      text: strategyName,
    },
  });

  
  chart.current.subscribeClick(myClickHandler);


}
function myClickHandler(param) {
  if (!param.point) {
      return;
  }

  //tempdata['opentime'] / 1000 - (3600 * 7),
  set_position_time(((parseInt(param.time) + (3600 * 7) )* 1000) )
  //alert(`Click at ${param.point.x}, ${param.point.y}. The time is ${param.time}.`)
  //console.log(`Click at ${param.point.x}, ${param.point.y}. The time is ${param.time}.`);
}


useEffect(() => {
  init_chart()
  get_candle_realtime()
  get_asset()
  const intervalId = setInterval(() => {
    get_candle_realtime()
    get_asset()
    
  }, 1000*10);
  return () => clearInterval(intervalId);
}, []);


useEffect(() => {
  const intervalId = setInterval(() => {
    //window.location.reload()
    let tempcnt = 0
    tempcnt = reflesh
    tempcnt = tempcnt + 1
    set_reflesh(tempcnt)
  }, 1000*4);
  return () => clearInterval(intervalId);
}, []);


useEffect(() => {
  const unixEpochTimeMS = convertEpocToDateString(start_trade_time)
  set_start_trade_time_local(unixEpochTimeMS)

  //console.log("useEffect, current_candle_data_length : "  + current_candle_data_length)
}, [start_trade_time]);


function convertEpocToDateString(epoctime) {
  let milliseconds = epoctime;
  let myDate = new Date(milliseconds-(60000*8));
  
  let dateStr =  myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds()
  return dateStr
}



useEffect(() => {
  //console.log("useEffect, current_candle_data_length : "  + current_candle_data_length)
}, [current_candle_data_length]);



return (
  <>
    <div style={{ margin: "0px 0px 0px 0px", width: "99%", background: "#1c1b2b", textAlign: "center" }}>
      <div>
        <label htmlFor="from_date" style={{ margin: "0px 0px 10px 10px", fontSize: "14px", color: "white" }}>
          Date :<label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>&nbsp;&nbsp;{start_trade_time_local + " ~ " + dateTo + " " + timeTo}</label>
          &nbsp;&nbsp;&nbsp;Crypto Profit: <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{parseFloat(asset.profit_coin_ratio).toFixed(5).toLocaleString("ko-KR", locale_opt) + "%"}</label>
          {/*&nbsp;&nbsp;&nbsp;Fiat Profit: <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{parseFloat(asset.profit_fiat_ratio).toFixed(5).toLocaleString("ko-KR", locale_opt) + "%"}</label>*/}
          &nbsp;&nbsp;&nbsp;Closed Trades: <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{parseFloat(asset.tradeCount).toLocaleString("ko-KR", locale_opt)}</label>
          &nbsp;&nbsp;&nbsp;Winning Ratio: <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{parseFloat(asset.profitable_trade).toFixed(3).toLocaleString("ko-KR", locale_opt) + "%"}</label>
        </label>
      </div>
    
      
      { reflesh && (
       <div ref={chartContainerRef} className="chart-container" />
      )
      }

    </div>
  </>

);
}

export default TradingView_Test;