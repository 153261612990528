import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import "../../../App.css";

const BB_Strategy_Parameter_Optimize = ({ setModalParameterOpen,strategy_name , exchange_name, coin_type, time_frame,entry_direction}) => {

    const [mini_profit_sell_enable_default, set_mini_profit_sell_enable_default] = useState("");
    const [mini_profit_sell_default, set_mini_profit_sell_default] = useState("");
    const [mini_profit_sell_min, set_mini_profit_sell_min] = useState("");
    const [mini_profit_sell_max, set_mini_profit_sell_max] = useState("");
    const [mini_profit_buy_enable_default, set_mini_profit_buy_enable_default] = useState("");
    const [mini_profit_buy_default, set_mini_profit_buy_default] =useState("");
    const [mini_profit_buy_min, set_mini_profit_buy_min] = useState("");
    const [mini_profit_buy_max, set_mini_profit_buy_max] = useState("");


    const [skip_enable_default, set_skip_enable_default] = useState("");
    const [skip_buy_count_default, set_skip_buy_count_default] = useState("");
    const [skip_buy_count_min, set_skip_buy_count_min] = useState("");
    const [skip_buy_count_max, set_skip_buy_count_max] = useState("");
    const [skip_sell_count_default, set_skip_sell_count_default] = useState("");
    const [skip_sell_count_min, set_skip_sell_count_min] = useState("");
    const [skip_sell_count_max, set_skip_sell_count_max] = useState("");


    const [bollinger_length_default, set_bollinger_length_default] = useState("");
    const [bollinger_length_min, set_bollinger_length_min] = useState("");
    const [bollinger_length_max, set_bollinger_length_max] = useState("");
    const [bollinger_dev_default, set_bollinger_dev_default] = useState("");
    const [bollinger_dev_min, set_bollinger_dev_min] = useState("");
    const [bollinger_dev_max, set_bollinger_dev_max] = useState("");


    const [shortlimitcondition_default, set_shortlimitcondition_default] = useState("");
    const [longlimitcondition_default, set_longlimitcondition_default] = useState("");

    const [bbw_short_set_enable_default, set_bbw_short_set_enable_default] = useState("");
    const [bbw_short_set_value_default, set_bbw_short_set_value_default] = useState("");
    const [bbw_short_set_value_min, set_bbw_short_set_value_min] = useState("");
    const [bbw_short_set_value_max, set_bbw_short_set_value_max] = useState("");

    const [bbw_long_set_enable_default, set_bbw_long_set_enable_default] = useState("");
    const [bbw_long_set_value_default, set_bbw_long_set_value_default] = useState("");
    const [bbw_long_set_value_min, set_bbw_long_set_value_min] = useState("");
    const [bbw_long_set_value_max, set_bbw_long_set_value_max] = useState("");


    const [short_base_price_default, set_short_base_price_default] = useState("");
    const [short_base_price_min, set_short_base_price_min] = useState("");
    const [short_base_price_max, set_short_base_price_max] = useState("");

    const [long_base_price_default, set_long_base_price_default] = useState("");
    const [long_base_price_min, set_long_base_price_min] = useState("");
    const [long_base_price_max, set_long_base_price_max] = useState("");

    const [parameter_default, set_parameter_json_default] = useState({});

    function valuetext(value) {
    return `${value}°C`;
    }

    function Apply() {

        if (window.confirm('Do you want to apply ?')) {

            var temp_param = {
                mini_profit_sell_enable_default: mini_profit_sell_enable_default,
                mini_profit_sell_default: mini_profit_sell_default,
                mini_profit_sell_min: mini_profit_sell_min,
                mini_profit_sell_max: mini_profit_sell_max,
                mini_profit_buy_enable_default: mini_profit_buy_enable_default,
                mini_profit_buy_default: mini_profit_buy_default,
                mini_profit_buy_min: mini_profit_buy_min,
                mini_profit_buy_max: mini_profit_buy_max,


                skip_enable_default: skip_enable_default,
                skip_buy_count_default: skip_buy_count_default,
                skip_buy_count_min: skip_buy_count_min,
                skip_buy_count_max: skip_buy_count_max,
                skip_sell_count_default: skip_sell_count_default,
                skip_sell_count_min: skip_sell_count_min,
                skip_sell_count_max: skip_sell_count_max,


                bollinger_length_default: bollinger_length_default,
                bollinger_length_min: bollinger_length_min,
                bollinger_length_max: bollinger_length_max,
                bollinger_dev_default: bollinger_dev_default,
                bollinger_dev_min: bollinger_dev_min,
                bollinger_dev_max: bollinger_dev_max,

                shortlimitcondition_default: shortlimitcondition_default,
                longlimitcondition_default: longlimitcondition_default,

                bbw_short_set_enable_default: bbw_short_set_enable_default,
                bbw_short_set_value_default: bbw_short_set_value_default,
                bbw_short_set_value_min: bbw_short_set_value_min,
                bbw_short_set_value_max: bbw_short_set_value_max,

                bbw_long_set_enable_default: bbw_long_set_enable_default,
                bbw_long_set_value_default: bbw_long_set_value_default,
                bbw_long_set_value_min: bbw_long_set_value_min,
                bbw_long_set_value_max: bbw_long_set_value_max,


                short_base_price_default: short_base_price_default,
                short_base_price_min: short_base_price_min,
                short_base_price_max: short_base_price_max,

                long_base_price_default: long_base_price_default,
                long_base_price_min: long_base_price_min,
                long_base_price_max: long_base_price_max,

                }

            
            console.log("temp_param  ==> " + JSON.stringify(temp_param))

            set_parameter_default(temp_param)
            setModalParameterOpen(false)

            }

    }
    function set_parameter_default(temp_param) {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        console.log("save_parameter_default !!!!")
     
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            strategy_name: strategy_name,
            exchange_name: exchange_name,
            symbol: coin_type,
            interval: time_frame,
            entry_direction : entry_direction,
            parameter_default: temp_param,
        };
      
        console.log("save_parameter_default ,data ==> " + JSON.stringify(data))

        axios
            .post(`${python_server_url}/save_parameter_default`, data)
            .then((res) => {
                console.log("save_parameter_default , res.data ==> " + JSON.stringify(res.data))
                if (res.data.length !== 0) {
                    
                } 
            })
            .catch((err) => {
                console.log(err);
            });
    }


    function get_parameter_default() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING

        const data = {
            strategy_name: strategy_name,
            exchange_name: exchange_name,
            symbol: coin_type,
            interval: time_frame,
            entry_direction : entry_direction
        };
      

        axios
            .post(`${python_server_url}/get_parameter_default`, data)
            .then((res) => {
                if (res.data.length !== 0) {
                    console.log("get_parameter_default , res.data.parameter_default ==> " + JSON.stringify(res.data.parameter_default))

                    var parameter_default = res.data.parameter_default
                    if (parameter_default != "" && parameter_default != undefined){
                        load_parameter_json(JSON.parse( parameter_default.replace(/'/g, '"')))
                    }

                } 
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function load_parameter_json(parameter_default){
        
        set_mini_profit_sell_enable_default(parameter_default['mini_profit_sell_enable_default']);
        set_mini_profit_sell_default(parameter_default['mini_profit_sell_default']);
        set_mini_profit_sell_min(parameter_default['mini_profit_sell_min']);
        set_mini_profit_sell_max(parameter_default['mini_profit_sell_max']);
        set_mini_profit_buy_enable_default(parameter_default['mini_profit_buy_enable_default']);
        set_mini_profit_buy_default(parameter_default['mini_profit_buy_default']);
        set_mini_profit_buy_min(parameter_default['mini_profit_buy_min']);
        set_mini_profit_buy_max(parameter_default['mini_profit_buy_max']);
        set_skip_enable_default(parameter_default['skip_enable_default']);
        set_skip_buy_count_default(parameter_default['skip_buy_count_default']);
        set_skip_buy_count_min(parameter_default['skip_buy_count_min']);
        set_skip_buy_count_max(parameter_default['skip_buy_count_max']);
        set_skip_sell_count_default(parameter_default['skip_sell_count_default']);
        set_skip_sell_count_min(parameter_default['skip_sell_count_min']);
        set_skip_sell_count_max(parameter_default['skip_sell_count_max']);
        set_bollinger_length_default(parameter_default['bollinger_length_default']);
        set_bollinger_length_min(parameter_default['bollinger_length_min']);
        set_bollinger_length_max(parameter_default['bollinger_length_max']);
        set_bollinger_dev_default(parameter_default['bollinger_dev_default']);
        set_bollinger_dev_min(parameter_default['bollinger_dev_min']);
        set_bollinger_dev_max(parameter_default['bollinger_dev_max']);
        set_shortlimitcondition_default(parameter_default['shortlimitcondition_default']);
        set_longlimitcondition_default(parameter_default['longlimitcondition_default']);
        set_bbw_short_set_enable_default(parameter_default['bbw_short_set_enable_default']);
        set_bbw_short_set_value_default(parameter_default['bbw_short_set_value_default']);
        set_bbw_short_set_value_min(parameter_default['bbw_short_set_value_min']);
        set_bbw_short_set_value_max(parameter_default['bbw_short_set_value_max']);
        set_bbw_long_set_enable_default(parameter_default['bbw_long_set_enable_default']);
        set_bbw_long_set_value_default(parameter_default['bbw_long_set_value_default']);
        set_bbw_long_set_value_min(parameter_default['bbw_long_set_value_min']);
        set_bbw_long_set_value_max(parameter_default['bbw_long_set_value_max']);



        set_short_base_price_default(parameter_default['short_base_price_default']);
        set_short_base_price_min(parameter_default['short_base_price_min']);
        set_short_base_price_max(parameter_default['short_base_price_max']);

        set_long_base_price_default(parameter_default['long_base_price_default']);
        set_long_base_price_min(parameter_default['long_base_price_min']);
        set_long_base_price_max(parameter_default['long_base_price_max']);

    }


    useEffect(() => {
        get_parameter_default()
    }, []);
    

    return (
        <>
            <div style={{ margin: "0px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>{strategy_name}</label>

                <div style={{ margin: "20px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                    <div>
                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>Price Condition Settings</label>
                    </div>
                    <table style={{ fontSize :"14px"}}>
                        <thead>
                            <tr>
                                <th style={{width: "20%" ,background: "#366CD0"}} >Parameter</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Default Value</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Range</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply Sell Profit</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_mini_profit_sell_enable_default(e.target.value); }} id="mini_profit_sell_enable_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={mini_profit_sell_enable_default}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-</td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Min Sell Profit %</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="mini_profit_sell_default" name="mini_profit_sell_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_sell_default} onChange={(e) => { set_mini_profit_sell_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="mini_profit_sell_min" name="mini_profit_sell_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_sell_min} onChange={(e) => { set_mini_profit_sell_min(e.target.value); }} />%
                                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="mini_profit_sell_max" name="mini_profit_sell_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_sell_max} onChange={(e) => { set_mini_profit_sell_max(e.target.value); }} />%
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Based on Short Price</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_short_base_price_default(e.target.value); }} id="short_base_price_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={short_base_price_default}>
                                            <option key="1" value="true">
                                                true
                                            </option>
                                            <option key="2" value="false">
                                                false
                                            </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply Buy Profit</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_mini_profit_buy_enable_default(e.target.value); }} id="mini_profit_buy_enable_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={mini_profit_buy_enable_default}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-</td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Min Buy Profit %</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="mini_profit_buy_default" name="mini_profit_buy_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_buy_default} onChange={(e) => { set_mini_profit_buy_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="mini_profit_buy_min" name="mini_profit_buy_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_buy_min} onChange={(e) => { set_mini_profit_buy_min(e.target.value); }} />%
                                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="mini_profit_buy_max" name="mini_profit_buy_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_buy_max} onChange={(e) => { set_mini_profit_buy_max(e.target.value); }} />%
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                <td style={{width: "20%"}}>Based on Long Price</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_long_base_price_default(e.target.value); }} id="long_base_price_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={long_base_price_default}>
                                            <option key="1" value="true">
                                                true
                                            </option>
                                            <option key="2" value="false">
                                                false
                                            </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-
                                    </td>
                            </tr>



                        </tbody>
                    </table>
                </div>

                <div style={{ margin: "20px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                    <div>
                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>Position Skip Settings</label>
                    </div>
                    <table style={{ fontSize :"14px"}}>
                        <thead>
                            <tr>
                                <th style={{width: "20%" ,background: "#366CD0"}} >Parameter</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Default Value</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Range</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Position Skip Settings</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_skip_enable_default(e.target.value); }} id="skip_enable_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={skip_enable_default}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-</td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Short Skip</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="skip_sell_count_default" name="skip_sell_count_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_sell_count_default} onChange={(e) => { set_skip_sell_count_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="skip_sell_count_min" name="skip_sell_count_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_sell_count_min} onChange={(e) => { set_skip_sell_count_min(e.target.value); }} />
                                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="skip_sell_count_max" name="skip_sell_count_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_sell_count_max} onChange={(e) => { set_skip_sell_count_max(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Long Skip</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="skip_buy_count_default" name="skip_buy_count_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_buy_count_default} onChange={(e) => { set_skip_buy_count_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="skip_buy_count_min" name="skip_buy_count_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_buy_count_min} onChange={(e) => { set_skip_buy_count_min(e.target.value); }} />
                                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="skip_buy_count_max" name="skip_buy_count_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_buy_count_max} onChange={(e) => { set_skip_buy_count_max(e.target.value); }} />
                                    </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div style={{ margin: "20px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                    <div>
                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>Bollinger Band Settings</label>
                    </div>
                    <table style={{ fontSize :"14px"}}>
                        <thead>
                            <tr>
                                <th style={{width: "20%" ,background: "#366CD0"}} >Parameter</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Default Value</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Range</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Bollinger Length</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bollinger_length_default" name="bollinger_length_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_length_default} onChange={(e) => { set_bollinger_length_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bollinger_length_min" name="bollinger_length_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_length_min} onChange={(e) => { set_bollinger_length_min(e.target.value); }} />
                                        <label htmlFor="bollinger_length_max" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="bollinger_length_max" name="bollinger_length_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_length_max} onChange={(e) => { set_bollinger_length_max(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Bollinger Dev.</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bollinger_dev_default" name="bollinger_dev_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_dev_default} onChange={(e) => { set_bollinger_dev_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bollinger_dev_min" name="bollinger_dev_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_dev_min} onChange={(e) => { set_bollinger_dev_min(e.target.value); }} />
                                        <label htmlFor="bollinger_dev_max" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="bollinger_dev_max" name="bollinger_dev_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_dev_max} onChange={(e) => { set_bollinger_dev_max(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply BBW Short</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_bbw_short_set_enable_default(e.target.value); }} id="bbw_short_set_enable_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={bbw_short_set_enable_default}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-</td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Short BBW</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bbw_short_set_value_default" name="bbw_short_set_value_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_short_set_value_default} onChange={(e) => { set_bbw_short_set_value_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bbw_short_set_value_min" name="bbw_short_set_value_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_short_set_value_min} onChange={(e) => { set_bbw_short_set_value_min(e.target.value); }} />
                                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="bbw_short_set_value_max" name="bbw_short_set_value_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_short_set_value_max} onChange={(e) => { set_bbw_short_set_value_max(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply BBW Long</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_bbw_long_set_enable_default(e.target.value); }} id="bbw_long_set_enable_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={bbw_long_set_enable_default}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-</td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Long BBW</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bbw_long_set_value_default" name="bbw_long_set_value_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_long_set_value_default} onChange={(e) => { set_bbw_long_set_value_default(e.target.value); }} />
                                    </td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bbw_long_set_value_min" name="bbw_long_set_value_min" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_long_set_value_min} onChange={(e) => { set_bbw_long_set_value_min(e.target.value); }} />
                                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "white" }}>~</label>
                                        <input type="text" id="bbw_long_set_value_max" name="bbw_long_set_value_max" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_long_set_value_max} onChange={(e) => { set_bbw_long_set_value_max(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Short Condition</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_shortlimitcondition_default(e.target.value); }} id="shortlimitcondition_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={shortlimitcondition_default}>
                                            <option key="1" value="bbb[1]>1">
                                                bbb[1]{'>'}1
                                            </option>
                                            <option key="2" value="and bbb<=1">
                                                and bbb{'<'}=1
                                            </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Long Condition</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_longlimitcondition_default(e.target.value); }} id="longlimitcondition_default" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={longlimitcondition_default}>
                                            <option key="1" value="bbb[1]<0">
                                                bbb[1]{'<'}0
                                            </option>
                                            <option key="2" value="and bbb>=0">
                                                and bbb{'>'}=0
                                            </option>
                                        </select>
                                    </td>
                                    <td style={{width: "20%"}}>-
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                
                <div style={{ margin: "20px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                    <button className="btn btn-primary btn-sm" onClick={() => Apply()} style={{ marginLeft: "auto" }}>Apply</button>
                </div>


            </div>
        </>

    );
}

export default BB_Strategy_Parameter_Optimize;