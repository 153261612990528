import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import "../../../App.css";

const BB_Strategy_Parameter_Test = ({ strategy_name, set_parameter }) => {

    const [mini_profit_sell_enable, set_profit_sell_enable] = useState("true");
    const [mini_profit_sell, set_profit_sell] = useState("1.0");
    const [mini_profit_buy_enable, set_profit_buy_enable] = useState("true");
    const [mini_profit_buy, set_profit_buy] = useState("1.0");

    const [bollinger_length, set_bollinger_length] = useState("20");
    const [bollinger_dev, set_bollinger_dev] = useState("1.5");

    const [bbw_short_set_enable, set_bbw_short_set_enable] = useState("true");
    const [bbw_short_set_value, set_bbw_short_set_value] = useState("0.000");
    const [bbw_long_set_enable, set_bbw_long_set_enable] = useState("true");
    const [bbw_long_set_value, set_bbw_long_set_value] = useState("0.000");

    const [skip_enable, set_skip_enable] = useState("true");
    const [skip_buy_count, set_skip_buy_count] = useState("0");
    const [skip_sell_count, set_skip_sell_count] = useState("0");

    const [shortlimitcondition, set_shortlimitcondition] = useState("bbb[1]>1");
    const [longlimitcondition, set_longlimitcondition] = useState("bbb[1]<0");

    const [stop_loss_sell_enable, set_stop_loss_sell_enable] = useState("false");
    const [stop_loss_sell, set_stop_loss_sell] = useState("3");
    const [stop_loss_buy_enable, set_stop_loss_buy_enable] = useState("false");
    const [stop_loss_buy, set_stop_loss_buy] = useState("3");


    const [short_base_price, set_short_base_price] = useState("false");
    const [long_base_price, set_long_base_price] = useState("false");



    const handle_profit_sell_enable = (e) => {
        set_profit_sell_enable(e.target.value);
    };

    

    function Apply() {

        var temp_param = {
            mini_profit_sell_enable: mini_profit_sell_enable,
            mini_profit_sell: mini_profit_sell,
            mini_profit_buy_enable: mini_profit_buy_enable,
            mini_profit_buy: mini_profit_buy,
            bollinger_length: bollinger_length,
            bollinger_dev: bollinger_dev,

            bbw_short_set_enable: bbw_short_set_enable,
            bbw_short_set_value: bbw_short_set_value,
            bbw_long_set_enable: bbw_long_set_enable,
            bbw_long_set_value: bbw_long_set_value,

            skip_enable: skip_enable,
            skip_buy_count: skip_buy_count,
            skip_sell_count: skip_sell_count,

            shortlimitcondition: shortlimitcondition,
            longlimitcondition: longlimitcondition,

            stop_loss_sell_enable: stop_loss_sell_enable,
            stop_loss_sell: stop_loss_sell,
            stop_loss_buy_enable: stop_loss_buy_enable,
            stop_loss_buy: stop_loss_buy,
            short_base_price: short_base_price,
            long_base_price: long_base_price,
        }

        set_parameter(temp_param)
    }


    return (
        <>
            <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>{strategy_name}</label>
            <div style={{display: "flex" ,margin: "0px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>

                <div style={{ margin: "20px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                    <div>
                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>Price Condition Settings</label>
                    </div>
                    <table style={{ fontSize :"14px"}}>
                        <thead>
                            <tr>
                                <th style={{width: "20%" ,background: "#366CD0"}} >Parameter</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply Sell Profit</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_profit_sell_enable(e.target.value); }} id="mini_profit_sell_enable" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={mini_profit_sell_enable}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Min Sell Profit %</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="mini_profit_sell" name="mini_profit_sell" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_sell} onChange={(e) => { set_profit_sell(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Based on Short Price</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_short_base_price(e.target.value); }} id="short_base_price" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={short_base_price}>
                                            <option key="1" value="true">
                                                true
                                            </option>
                                            <option key="2" value="false">
                                                false
                                            </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply Buy Profit</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_profit_buy_enable(e.target.value); }} id="mini_profit_buy_enable" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={mini_profit_buy_enable}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Min Buy Profit %</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="mini_profit_buy" name="mini_profit_buy" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={mini_profit_buy} onChange={(e) => { set_profit_buy(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Based on Long Price</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_long_base_price(e.target.value); }} id="long_base_price" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={long_base_price}>
                                            <option key="1" value="true">
                                                true
                                            </option>
                                            <option key="2" value="false">
                                                false
                                            </option>
                                        </select>
                                    </td>
                            </tr>


                        </tbody>
                    </table>
                </div>

                <div style={{ margin: "20px 0px 0px 20px", width: "100%", background: "#1c1b2b" }}>
                    <div>
                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>Position Skip Settings</label>
                    </div>
                    <table style={{ fontSize :"14px"}}>
                        <thead>
                            <tr>
                                <th style={{width: "20%" ,background: "#366CD0"}} >Parameter</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Default Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Position Skip Settings</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_skip_enable(e.target.value); }} id="skip_enable" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={skip_enable}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Short Skip</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="skip_sell_count" name="skip_sell_count" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_sell_count} onChange={(e) => { set_skip_sell_count(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Long Skip</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="skip_buy_count" name="skip_buy_count" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={skip_buy_count} onChange={(e) => { set_skip_buy_count(e.target.value); }} />
                                    </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div style={{ margin: "20px 0px 0px 20px", width: "100%", background: "#1c1b2b" }}>
                    <div>
                        <label htmlFor="strategy_name" style={{ margin: "0px 0px 0px 0px", fontSize: "14px", color: "yellow" }}>Bollinger Band Settings</label>
                    </div>
                    <table style={{ fontSize :"14px"}}>
                        <thead>
                            <tr>
                                <th style={{width: "20%" ,background: "#366CD0"}} >Parameter</th>
                                <th style={{width: "30%" ,background: "#366CD0"}} >Default Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Bollinger Length</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bollinger_length" name="bollinger_length" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_length} onChange={(e) => { set_bollinger_length(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Bollinger Dev.</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bollinger_dev" name="bollinger_dev" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bollinger_dev} onChange={(e) => { set_bollinger_dev(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply BBW Short</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_bbw_short_set_enable(e.target.value); }} id="bbw_short_set_enable" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={bbw_short_set_enable}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Short BBW</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bbw_short_set_value" name="bbw_short_set_value" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_short_set_value} onChange={(e) => { set_bbw_short_set_value(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Apply BBW Long</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_bbw_long_set_enable(e.target.value); }} id="bbw_long_set_enable" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={bbw_long_set_enable}>
                                        <option key="1" value="true">
                                            true
                                        </option>
                                        <option key="2" value="false">
                                            false
                                        </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Long BBW</td>
                                    <td style={{width: "20%"}}>
                                        <input type="text" id="bbw_long_set_value" name="bbw_long_set_value" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }}
                                                                                    defaultValue={bbw_long_set_value} onChange={(e) => { set_bbw_long_set_value(e.target.value); }} />
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Short Condition</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_shortlimitcondition(e.target.value); }} id="shortlimitcondition" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={shortlimitcondition}>
                                            <option key="1" value="bbb[1]>1">
                                                bbb[1]{'>'}1
                                            </option>
                                            <option key="2" value="and bbb<=1">
                                                and bbb{'<'}=1
                                            </option>
                                        </select>
                                    </td>
                            </tr>
                            <tr className="logTr" style={{background: "#233E6F"}} >
                                    <td style={{width: "20%"}}>Long Condition</td>
                                    <td style={{width: "20%"}}>
                                        <select onChange={(e) => { set_longlimitcondition(e.target.value); }} id="longlimitcondition" style={{ margin: "0px 0px 0px 10px", fontSize: "14px", width: "100px" }} value={longlimitcondition}>
                                            <option key="1" value="bbb[1]<0">
                                                bbb[1]{'<'}0
                                            </option>
                                            <option key="2" value="and bbb>=0">
                                                and bbb{'>'}=0
                                            </option>
                                        </select>
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div style={{ margin: "20px 0px 0px 0px", width: "100%", background: "#1c1b2b" }}>
                <button className="btn btn-primary btn-sm" onClick={() => Apply()} style={{ marginLeft: "auto" }}>Test</button>
            </div>

        </>

    );
}

export default BB_Strategy_Parameter_Test;