import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import HeaderNew from "../../headerFooterSide/HeaderNew";
import BackTestingSpot from "./BackTestingSpot";
import { DateTime_Div } from "../../common/Commons";

function BackTesting({handleLogoutapp}) {
    const [title, setTile] = useState()

    const [timeframe, setTimeFrame] = useState("time_frame_1");    
    
    const [wait_count_enable, set_wait_count_enable] = useState(false);
    const [wait_count, set_wait_count] = useState(0);

    const [mini_profit_enable, set_mini_profit_enable] = useState(false);
    const [mini_profit_buy, set_mini_profit_buy] = useState(0);
    const [mini_profit_sell, set_mini_profit_sell] = useState(0);

    const [stop_loss_enable, set_stop_loss_enable] = useState(false);
    const [stop_loss_buy, set_stop_loss_buy] = useState(0);
    const [stop_loss_sell, set_stop_loss_sell] = useState(0);


    const [skip_enable, set_skip_enable] = useState(false);
    const [skip_buy_count, set_skip_buy_count] = useState(0);
    const [skip_sell_count, set_skip_sell_count] = useState(0);

    const [all_signal_enable, set_all_signal_enable] = useState(false);

    
    const [repainting, set_repainting] = useState(1)
    const [backtesting_from_date, set_backtesting_from_date] = useState();
    const [backtesting_to_date, set_backtesting_to_date] = useState();
    
    const [local_backtesting_from_date, set_local_backtesting_from_date] = useState();
    const [local_backtesting_to_date, set_local_backtesting_to_date] = useState();

    const [backtesting_type, set_backtesting_type] = useState("");

    const [switching_reset, set_switching_reset] = useState();

    const [dateFrom, setDateFrom] = useState("all");
    const [dateTo, setDateTo] = useState("all");
    const [timeFrom, setTimeFrom] = useState("");
    const [timeTo, setTimeTo] = useState("");


    const [temp_date_time, set_temp_date_time] = useState();

    const [chartReset, set_chartReset] = useState(false);
    const [condition_json_file_name, set_condition_json_file_name] = useState("condition_1.json")
    const condition_json_file_name_list = [
        { value: "condition_0.json", name: "condition_0.json" },
        { value: "condition_1.json", name: "condition_1.json" },
        { value: "condition_2.json", name: "condition_2.json" },
        { value: "condition_3.json", name: "condition_3.json" },
        { value: "condition_4.json", name: "condition_4.json" },
        { value: "condition_5.json", name: "condition_5.json" },
        { value: "condition_6.json", name: "condition_6.json" },
        { value: "condition_7.json", name: "condition_7.json" },
        { value: "condition_8.json", name: "condition_8.json" }
    ];
   
    const handleSelect = (e) => {
        set_condition_json_file_name(e.target.value);
      };

    function do_switching(){
        do_switching_reset() 
    }
    function repaint() {
        var temprepainting = repainting
        temprepainting = temprepainting + 1
        set_repainting(temprepainting)
    }

    function saveTimeFrameToSession(timeframe_){
        window.sessionStorage.setItem("timeframe", timeframe_);
        setTimeFrame(timeframe_)

    }

    function convert_to_localtime(utcTime){
        //var date = new Date(utcTime - (3600000*8)).getTime();;
        //var tempPacificDateTime = new Date(utcTime - (3600000*8)).getTime();
        var tempPacificDateTime = new Date(utcTime);;
   
        //var datestr =  tempPacificDateTime.toLocaleDateString("en-US")
        /*
        var tempDate= tempPacificDateTime.getFullYear().toString()
        tempDate = tempDate + "-" + tempPacificDateTime.getMonth().toString() 
        tempDate = tempDate + "-" + tempPacificDateTime.getDate().toString() 
        tempDate = tempDate + "-" + tempPacificDateTime.getHours().toString() 
        tempDate = tempDate + "-" + tempPacificDateTime.getMinutes() + "AM"
        */
        set_local_backtesting_from_date( tempPacificDateTime.toString())  
    }

    function set_trading_json(){
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            condition_json_file_name : condition_json_file_name
        };
        console.log("set_trading_json, condition_json_file_name ===> " + condition_json_file_name)
        console.log("set_trading_json, data ===> " + JSON.stringify(data))

        axios
            .post(`${python_server_url}/set_trading_json`, data)
            .then((res) => {
                console.log(`set_trading_json, return value : ${JSON.stringify(res.data)}`)
                if (res.data.status == "ok") {
                    set_chartReset(true)
                    window.location.reload();

                } else {
                    alert(res.data.error + " , So System can not apply it !");
                }
            })
            .catch((err) => {
                alert(err);
                console.log(err);
            });
    }

    function do_switching_reset() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
        };
        //console.log("python_server_url ===> ")
        console.log("do_switching_reset, python_server_url ===> " + python_server_url)
        console.log("do_switching_reset, set_trading_type_reset")
        console.log("do_switching_reset, data ===> " + JSON.stringify(data))

        axios
            .post(`${python_server_url}/set_switching_reset`, data)
            .then((res) => {
                console.log(`return set_switching_reset : ${JSON.stringify(res.data)}`)
                if (res.data.status == "ok") {

                    set_backtesting_type(res.data.data.backtesting_type)
                    
                    set_wait_count_enable(res.data.data.wait_count_enable)
                    set_wait_count(res.data.data.wait_count)

                    set_mini_profit_enable(res.data.data.mini_profit_enable)
                    set_mini_profit_buy(res.data.data.mini_profit_buy)
                    set_mini_profit_sell(res.data.data.mini_profit_sell)

                    set_stop_loss_enable(res.data.data.stop_loss_enable)
                    set_stop_loss_buy(res.data.data.stop_loss_buy)
                    set_stop_loss_sell(res.data.data.stop_loss_sell)

                    set_skip_enable(res.data.data.skip_enable)
                    set_skip_buy_count(res.data.data.skip_buy_count)
                    set_skip_sell_count(res.data.data.skip_sell_count)

                    set_all_signal_enable(res.data.data.all_signal_enable)
                    
                    set_backtesting_from_date(res.data.data.backtesting_from_date)
                    set_condition_json_file_name(res.data.data.condition_json_file_name)
                    set_chartReset(true)
                    window.location.reload();

                } else {
                    alert(res.data.error + " , So System can not appy it !");
                }

             
            })
            .catch((err) => {
                alert(err);
                console.log(err);
            });

    }
    function set_trading_type_from_now(){
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            backtesting_type : backtesting_type,

            wait_count_enable :wait_count_enable,
            wait_count : wait_count,

            mini_profit_enable : mini_profit_enable,
            mini_profit_buy : mini_profit_buy,
            mini_profit_sell : mini_profit_sell,

            stop_loss_enable : stop_loss_enable,
            stop_loss_buy : stop_loss_buy,
            stop_loss_sell : stop_loss_sell,

            skip_enable : skip_enable,
            skip_buy_count : skip_buy_count,
            skip_sell_count : skip_sell_count,
            condition_json_file_name : condition_json_file_name
        };
        console.log("set_trading_type_from_now, python_server_url ===> " + python_server_url)
        console.log("set_trading_type_from_now, set_trading_type_reset")
        console.log("set_trading_type_from_now, data ===> " + JSON.stringify(data))

        axios
            .post(`${python_server_url}/set_trading_type_from_now`, data)
            .then((res) => {
                console.log(`return set_trading_type_from_now : ${JSON.stringify(res.data)}`)
                if (res.data.status == "ok") {

                    set_backtesting_type(res.data.data.backtesting_type)
                    
                    set_wait_count_enable(res.data.data.wait_count_enable)
                    set_wait_count(res.data.data.wait_count)

                    set_mini_profit_enable(res.data.data.mini_profit_enable)
                    set_mini_profit_buy(res.data.data.mini_profit_buy)
                    set_mini_profit_sell(res.data.data.mini_profit_sell)

                    set_stop_loss_enable(res.data.data.stop_loss_enable)
                    set_stop_loss_buy(res.data.data.stop_loss_buy)
                    set_stop_loss_sell(res.data.data.stop_loss_sell)

                    set_skip_enable(res.data.data.skip_enable)
                    set_skip_buy_count(res.data.data.skip_buy_count)
                    set_skip_sell_count(res.data.data.skip_sell_count)


                    set_all_signal_enable(res.data.data.all_signal_enable)

                    set_backtesting_from_date(res.data.data.backtesting_from_date)

                    set_condition_json_file_name(res.data.data.condition_json_file_name)

                    set_chartReset(true)
                    window.location.reload();

                } else {
                    alert(res.data.error + " , So System can not appy it !");
                }

             
            })
            .catch((err) => {
                alert(err);
                console.log(err);
            });

    }

    function set_trading_type_reset() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
            backtesting_type : backtesting_type,
            wait_count_enable :wait_count_enable,
            wait_count : wait_count,

            mini_profit_enable : mini_profit_enable,
            mini_profit_buy : mini_profit_buy,
            mini_profit_sell : mini_profit_sell,

            stop_loss_enable : stop_loss_enable,
            stop_loss_buy : stop_loss_buy,
            stop_loss_sell : stop_loss_sell,

            skip_enable : skip_enable,
            skip_buy_count : skip_buy_count,
            skip_sell_count : skip_sell_count,

            all_signal_enable : all_signal_enable,

            backtesting_from_date : backtesting_from_date ,
            backtesting_to_date : backtesting_to_date ,
            condition_json_file_name : condition_json_file_name
        };
        console.log("python_server_url ===> ")
        console.log("set_trading_type_reset")
        console.log("data ===> " + JSON.stringify(data))

        
        axios
            .post(`${python_server_url}/set_trading_type_reset`, data)
            .then((res) => {
                console.log(`return set_trading_type_reset : ${JSON.stringify(res.data)}`)
                if (res.data.status == "ok") {

                    set_backtesting_type(res.data.data.backtesting_type)
                    set_wait_count(res.data.data.wait_count)
                    set_mini_profit_buy(res.data.data.mini_profit_buy)
                    set_mini_profit_sell(res.data.data.mini_profit_sell)

                    set_stop_loss_buy(res.data.data.stop_loss_buy)
                    set_stop_loss_sell(res.data.data.stop_loss_sell)

                    set_skip_buy_count(res.data.data.skip_buy_count)
                    set_skip_sell_count(res.data.data.skip_sell_count)

                    set_all_signal_enable(res.data.data.all_signal_enable)

                    set_backtesting_from_date(res.data.data.backtesting_from_date)
                    set_backtesting_to_date(res.data.data.backtesting_to_date)


                    set_condition_json_file_name(res.data.data.condition_json_file_name)
                    
                    set_chartReset(true)
                    window.location.reload();

                } else {
                    alert(res.data.error + " , So System can not appy it !");
                }

             
            })
            .catch((err) => {
                alert(err);
                console.log(err);
            });

    }


    function get_trading_type() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        const data = {
            type: "spot",
            exchage: "binance",
            symbol: "btc",
        };
        //console.log("python_server_url ===> ")
        //console.log(python_server_url)
        //console.log("data ===> ")
        //console.log(JSON.stringify(data))

        axios
            .post(`${python_server_url}/get_trading_type`, data)
            .then((res) => {
                console.log("get_trading_type ==>" + JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    //////////////
                    set_backtesting_type(res.data.data.backtesting_type)
                    
                    set_wait_count_enable(res.data.data.wait_count_enable == "True")
                    set_wait_count(res.data.data.wait_count)

                    set_mini_profit_enable(res.data.data.mini_profit_enable == "True")
                    set_mini_profit_buy(res.data.data.mini_profit_buy)
                    set_mini_profit_sell(res.data.data.mini_profit_sell)

                    set_stop_loss_enable(res.data.data.stop_loss_enable == "True")
                    set_stop_loss_buy(res.data.data.stop_loss_buy)
                    set_stop_loss_sell(res.data.data.stop_loss_sell)

                    set_skip_enable(res.data.data.skip_enable == "True")
                    set_skip_buy_count(res.data.data.skip_buy_count)
                    set_skip_sell_count(res.data.data.skip_sell_count)


                    set_all_signal_enable(res.data.data.all_signal_enable == "True")
                    
                    set_backtesting_from_date(parseInt(res.data.data.backtesting_from_date))
                    set_backtesting_to_date(parseInt(res.data.data.backtesting_to_date))
                    convert_to_localtime(parseInt(res.data.data.backtesting_from_date))

                    set_condition_json_file_name(res.data.data.condition_json_file_name)

                    set_switching_reset(res.data.data.switching_reset)
                    

                    var temp_date = new Date(parseInt(res.data.data.backtesting_from_date));
                    var temp_month = parseInt(temp_date.getMonth()) + 1

                    var temp_month_init =  temp_month
                    var temp_month_str = ""
                    if (temp_month_init < 10){
                        temp_month_str =  "0" + temp_month_init.toString()
                    }else{
                        temp_month_str =  temp_month_init.toString()
                    }

                    var temp_date_init =  temp_date.getDate() 
                    var temp_date_init_str = ""
                    if (temp_date_init < 10){
                        temp_date_init_str =  "0" + temp_date_init.toString()
                    }else{
                        temp_date_init_str =  temp_date_init.toString()
                    }

                    var temp_full_date = temp_date.getFullYear() + "-" + temp_month_str + "-" +  temp_date_init_str
                    setDateFrom(temp_full_date)
                    console.log("temp_full_date_from ==> " + temp_full_date)

                    var temp_minit_init =  parseInt(temp_date.getMinutes())
                    var temp_minit_str = ""
                    if (temp_minit_init < 10){
                        temp_minit_str =  "0" + temp_minit_init.toString()
                    }else{
                        temp_minit_str =  temp_minit_init.toString()
                    }
                    var temp_full_time = temp_date.getHours() + ":" + temp_minit_str
                    setTimeFrom(temp_full_time)
                    console.log("temp_full_time_from ==> " + temp_full_time)

                    ///////////////////////////////////////////////////

                    var temp_date_to = new Date(parseInt(res.data.data.backtesting_to_date));
                    var temp_month_to = parseInt(temp_date_to.getMonth()) + 1

                    var temp_month_init_to =  temp_month_to
                    var temp_month_str_to = ""
                    if (temp_month_init_to < 10){
                        temp_month_str_to =  "0" + temp_month_init_to.toString()
                    }else{
                        temp_month_str_to =  temp_month_init_to.toString()
                    }


                    var temp_date_init_to =  temp_date_to.getDate() 
                    var temp_date_init_str_to = ""
                    if (temp_date_init_to < 10){
                        temp_date_init_str_to =  "0" + temp_date_init_to.toString()
                    }else{
                        temp_date_init_str_to =  temp_date_init_to.toString()
                    }

                    var temp_full_date_to = temp_date_to.getFullYear() + "-" + temp_month_str_to + "-" +  temp_date_init_str_to
                    setDateTo(temp_full_date_to)

                    console.log("temp_full_date_to ==> " + temp_full_date_to)

                    var temp_minit_init_to =  parseInt(temp_date.getMinutes())
                    var temp_minit_str_to = ""
                    if (temp_minit_init_to < 10){
                        temp_minit_str_to =  "0" + temp_minit_init_to.toString()
                    }else{
                        temp_minit_str_to =  temp_minit_init_to.toString()
                    }
                    var temp_full_time_to = temp_date_to.getHours() + ":" + temp_minit_str_to
                    setTimeTo(temp_full_time_to)
                    console.log("temp_full_time_to ==> " + temp_full_time_to)

                    //{"data":{"force_finish_enable":"false","skip_enable":"false","mini_profit_buy":"3","mini_profit_sell":"3","wait_count":"1","wait_enable":"false"},"status":"ok"}
                    //set_repainting(repaint+1)

                } 
            })
            .catch((err) => {
                //alert(err);
                console.log(err);
            });

    }

    useEffect(() => {
        console.log("useEffect , 최초 실행!")
        setTile("BackTesting Spot")
        get_trading_type()
        
    }, []);


    useEffect(() => {
        if(dateFrom !="" && dateTo !="" && timeFrom !="" && timeTo !=""){
            let temp_date = dateFrom.split("-");
            let temp_time = timeFrom.split(":");
            var from_date_time = new Date(parseInt(temp_date[0]), parseInt(temp_date[1]-1), parseInt(temp_date[2]), parseInt(temp_time[0]), parseInt(temp_time[1]), "00");
            console.log("from_date_time ==>" + from_date_time)


            temp_date = dateTo.split("-");
            temp_time = timeTo.split(":");
            var to_date_time =  new Date(parseInt(temp_date[0]), parseInt(temp_date[1]-1), parseInt(temp_date[2]), parseInt(temp_time[0]), parseInt(temp_time[1]), "00");
            console.log("to_date_time ==>" + to_date_time)


            var from_date_time_time_Epoch = Date.parse(from_date_time.toString());
            console.log("from_date_time_time_Epoch ==>" + from_date_time_time_Epoch)
            set_backtesting_from_date(from_date_time_time_Epoch)

            var to_date_time_Epoch = Date.parse(to_date_time.toString());
            console.log("to_date_time_Epoch ==>" + to_date_time_Epoch)
            set_backtesting_to_date(to_date_time_Epoch)


            //set_temp_date_time(to_date_time.toString() + to_date_time.toString())

        }
    }, [dateFrom,dateTo,timeFrom,timeTo]);

    /*
    useEffect(() => {
        console.log(`backtesting_type , 2 값: ${backtesting_type}`);
    
        return () => {
          console.log(`backtesting_type , 2 값: ${backtesting_type}`);
        };
      }, [backtesting_type]);
    */

    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br></br>
            <div>
                <div style={{margin: "1px 0px 0px 0px"}}>
                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"90%" ,background:"#1c1b2b"}}>
                            {/* 
                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 10px 0px" }}>     
                                <button className="btn btn-success btn-sm" style={{ width: '140px', display: "float" }} onClick={() => do_switching()}>
                                Switching Reset
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <label htmlFor="switching_reset" style={{margin: "0px 20px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}>(switching reset value : {switching_reset})</label>
                                
                            </div>
                            */}

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 15px 0px" }}>     
                                <label htmlFor="wait_count_enable" style={{margin: "0px 20px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Select Trading Rule</label>
                            
                                <select onChange={handleSelect}
                                    value={condition_json_file_name}>
                                    {condition_json_file_name_list.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select><p>
                                         <b>{condition_json_file_name}</b>
                                        </p>
                            </div>

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "0px 0px 0px 0px" }}>     
                                
                                <label htmlFor="wait_count_enable" style={{margin: "0px 20px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Enable Withholding</label>
                                <input type="checkbox" style={{ width: "15px" , height : "15px" }} id="wait_count_enable" name="wait_count_enable" checked={wait_count_enable === true} readOnly onChange={() => set_wait_count_enable(!wait_count_enable)}  />
                                
                                <label htmlFor="wait_count"  style={{margin: "0px 10px 0px 30px", fontSize :"12px",  color: "white" }}> Withholding Count : </label>
                                <input type="text" id="wait_count" name="wait_count" style={{ width: "100px" }} value={wait_count}  onChange={(e) => set_wait_count(e.target.value)} />
                                <button className="btn btn-success btn-sm" style={{ margin: "0px 0px 0px 20px", width: '100px', display: "float" }} onClick={() => set_trading_type_from_now()}>
                                 Update 
                                </button>
                            </div>

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "10px 0px 0px 0px" }}>     
                                <label htmlFor="mini_profit_enable" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Enable Mini Profit</label>
                                
                                <input type="checkbox" style={{ width: "15px" , height : "15px" }} id="mini_profit_enable" name="mini_profit_enable" checked={mini_profit_enable === true} readOnly onChange={() => set_mini_profit_enable(!mini_profit_enable)}  />

                                {mini_profit_buy}
                                <label htmlFor="mini_profit_buy"  style={{margin: "0px 20px 0px 30px", fontSize :"12px",  color: "white" }}> Buy : </label>
                                <input type="text" id="mini_profit_buy" name="mini_profit_buy" style={{ width: "100px" }} value={mini_profit_buy}  onChange={(e) => set_mini_profit_buy(e.target.value)} />
                                
                                {mini_profit_sell}
                                <label htmlFor="mini_profit_sell"  style={{margin: "0px 0px 0px 20px", fontSize :"12px",  color: "white" }}> Sell :&nbsp;&nbsp;</label>
                                <input type="text" id="mini_profit_sell" name="mini_profit_sell" style={{ width: "100px" }} value={mini_profit_sell}   onChange={(e) => set_mini_profit_sell(e.target.value)} />
                                <button className="btn btn-success btn-sm" style={{ margin: "0px 0px 0px 20px", width: '100px', display: "float" }} onClick={() => set_trading_type_from_now()}>
                                Update
                                </button>
                            </div>

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "10px 0px 0px 0px" }}>     
                                <label htmlFor="stop_loss_enable" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Enable Stop Loss</label>
                                
                                <input type="checkbox" style={{ width: "15px" , height : "15px" }} id="stop_loss_enable" name="stop_loss_enable" checked={stop_loss_enable === true} readOnly onChange={() => set_stop_loss_enable(!stop_loss_enable)}  />

                                {stop_loss_buy}
                                <label htmlFor="stop_loss_buy"  style={{margin: "0px 20px 0px 30px", fontSize :"12px",  color: "white" }}> Buy : </label>
                                <input type="text" id="stop_loss_buy" name="stop_loss_buy" style={{ width: "100px" }} value={stop_loss_buy}  onChange={(e) => set_stop_loss_buy(e.target.value)} />
                                
                                {stop_loss_sell}
                                <label htmlFor="stop_loss_sell"  style={{margin: "0px 0px 0px 20px", fontSize :"12px",  color: "white" }}> Sell : &nbsp;&nbsp;</label>
                                <input type="text" id="stop_loss_sell" name="stop_loss_sell" style={{ width: "100px" }} value={stop_loss_sell}   onChange={(e) => set_stop_loss_sell(e.target.value)} />
                                <button className="btn btn-success btn-sm" style={{ margin: "0px 0px 0px 20px", width: '100px', display: "float" }} onClick={() => set_trading_type_from_now()}>
                                Update
                                </button>
                            </div>


                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "10px 0px 10px 0px" }}>     
                                <label htmlFor="skip_enable" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Skip pyramiding</label>
                                 <input type="checkbox" style={{ width: "15px" , height : "15px" }} id="skip_enable" namex="skip_enable" checked={skip_enable === true} readOnly onChange={() => set_skip_enable(!skip_enable)}  />

                                <label htmlFor="skip_buy_count"  style={{margin: "0px 20px 0px 30px", fontSize :"12px",  color: "white" }}> Buy Count : </label>
                                <input type="text" id="skip_buy_count" name="skip_buy_count" style={{ width: "100px" }} value={skip_buy_count}  onChange={(e) => set_skip_buy_count(e.target.value)} />

                                <label htmlFor="skip_sell_count"  style={{margin: "0px 0px 0px 20px", fontSize :"12px",  color: "white" }}> Sell Count : &nbsp;&nbsp;</label>
                                <input type="text" id="skip_sell_count" name="skip_sell_count" style={{ width: "100px" }} value={skip_sell_count}   onChange={(e) => set_skip_sell_count(e.target.value)} />
                               

                            </div>

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "20px 0px 10px 0px" }}>  
                                <label htmlFor="all_signal_enable" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> All Signal</label>
                                 <input type="checkbox" style={{ width: "15px" , height : "15px" }} id="all_signal_enable" namex="all_signal_enable" checked={all_signal_enable ===true} readOnly onChange={() => set_all_signal_enable(!all_signal_enable)}  />
   
                                
                            </div>

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "30px 0px 10px 0px" }}>     

                                <label htmlFor="" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Start Date</label>
                                <DateTime_Div dateFrom={dateFrom} timeFrom={timeFrom} dateTo={dateTo} timeTo={timeTo}  send_data_from={setDateFrom} send_data_to={setDateTo}  send_time_from={setTimeFrom} set_time_to={setTimeTo} />

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label htmlFor="" style={{margin: "0px 10px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> Epoctime : </label>
                                <label htmlFor="" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> &nbsp;&nbsp;[{backtesting_from_date}]</label>
                                <label htmlFor="" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",  cursor: "pointer",color: "white" }}> ~&nbsp;&nbsp;[{backtesting_to_date}]</label>

                                {/*<label htmlFor="" style={{margin: "0px 25px 0px 0px",  fontSize :"12px",color: "white" }}> &nbsp;&nbsp;({temp_date_time})</label>*/}
                            </div>

                            <div style={{ width:"100%" , justifyContent: "left", display: "flex",margin: "20px 0px 10px 0px" }}>   
                                <button className="btn btn-primary btn-sm" style={{ width: '200px', display: "float" }} onClick={() => set_trading_type_reset()}>
                                Apply (Restart)
                                </button>
                            </div>

                        </div>
                        
                    <div width="100%" style={{  display: "flex",margin: "0px 0px 0px 0px" }}>
                        <div style={{ width:"90%", justifyContent: "center" ,margin: "0px 20px 0px 0px" }}>
                        <BackTestingSpot/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default BackTesting;
