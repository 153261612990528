import React, { useState, useEffect } from "react";

import axios from "axios";
import dayjs from "dayjs";

import "./botStatusCurrentBalance.css";
import { locale_opt } from "../../../common/Commons";

function BotStatus_new({ botStatus , exchangesSelect, botCnt, balanceStatus ,business_type}) {

    //alert(exchangesSelect)
    //alert(JSON.stringify(balanceStatus))
    // edit button
    const [editButtons, setEditButtons] = useState([""]);

    const handleEdit = (id,mode) => {
        console.log("handleEdit mode :" + mode);
        console.log("handleEdit id :" + id);
        let newArr = [...editButtons]; 
        if ( mode === "edit" ) {
            newArr[id] = "edit"; 
            //alert(id)
            setEditButtons(newArr);
            
        }else{
           //alert(id)
          newArr[id] = "save"; 
          setEditButtons(newArr);
        }
       // replace e.trigger.value with whatever you want to change it to
       
      };


      function setInput_trigger_price(id,value){
        value = value.replace(".","")
        document.getElementById(id).value = value;
      }

      const save_trigger_price = (title,id) => {
        if (window.confirm(title)) {

            let temp_trigger_price = document.getElementById("buy_pair_trigger_price" + id).value

            //alert(temp_trigger_price);

            if (temp_trigger_price == ""  ) {
                alert("Please check trigger price !");
                return false;
            }
            
            const data = {
                bot_id: "p_bot",
                msg: "set_trigger_price_for_tradingview",
                buy_pair_trigger_price: temp_trigger_price,
                id: id,
            };
            //alert(JSON.stringify(data))

            axios
            .post(`${process.env.REACT_APP_PYTHON_SERVER}/bots`, data)
            .then((res) => {
                //alert(res.data.msg);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
            
        }
    };

    function setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id){

        let time = dayjs().format("YYYY-MM-DD hh:mm:ss");
        let order_type ="market";

        if (msg == "close"){
            order_type ="market";
        }else if( msg =="trigger"){
            order_type ="automarket";
        }
        else {
            order_type ="";
        }

        //alert(strategy_group);
        const this_data = {
                getmodel : "botScheduleCtl_model",
                gettogo : "trading_view",

                params : {
                    trading_type : "pyramiding",
                    id : id,
                    buy_pair_id : buy_pair_id,
                    strategy_group: strategy_group_no,
                    strategy_name: strategy_name,
                    exchange_name: exchanges_str + ":" + coin_type + fiat_type,
                    coin_type: coin_type + fiat_type,
                    time_frame: time_frame,
                    position: "BUY",
                    price: "0",
                    buy_pair_trigger_price : buy_pair_trigger_price ,
                    operation_type: msg,
                    order_type: order_type,
                    alarm_sent_time: String(new Date(time) / 1000)
                }
        };
        //alert(JSON.stringify(this_data))
        
        axios
            .post(process.env.REACT_APP_TV_SERVER, this_data)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err));
        

    }
    //즉시 매수하고 , 파라메터 업데이타함
    function setTrading_optimizer_terminate(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id){

        let time = dayjs().format("YYYY-MM-DD hh:mm:ss");
        let order_type ="market";

        if (msg == "close"){
            order_type ="market";
        }else if( msg =="trigger"){
            order_type ="automarket";
        }
        else {
            order_type ="";
        }

        //alert(strategy_group);
        const this_data = {
                getmodel : "botScheduleCtl_model",
                gettogo : "trading_view",

                params : {
                    trading_type : "optimize",
                    id : id,
                    buy_pair_id : buy_pair_id,
                    strategy_group: strategy_group_no,
                    strategy_name: strategy_name,
                    exchange_name: exchanges_str + ":" + coin_type + fiat_type,
                    coin_type: coin_type + fiat_type,
                    time_frame: time_frame,
                    position: "BUY",
                    price: "0",
                    buy_pair_trigger_price : buy_pair_trigger_price ,
                    operation_type: msg,
                    order_type: order_type,
                    alarm_sent_time: String(new Date(time) / 1000)
                }
        };
        //alert(JSON.stringify(this_data))
        
        axios
            .post(process.env.REACT_APP_TV_SERVER, this_data)
            .then((res) => {

                alert(res.data.msg)
                window.location.reload();
            })
            .catch((err) => console.log(err));
        

    }
    //Terminate 누르면 봇 종료되고 Run이 off로 된다.
    function setTrading_optimizer_reset(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id){

        let time = dayjs().format("YYYY-MM-DD hh:mm:ss");
        let order_type ="market";

        if (msg == "close"){
            order_type ="market";
        }else if( msg =="trigger"){
            order_type ="automarket";
        }
        else {
            order_type ="";
        }

        //alert(strategy_group);
        const this_data = {
                getmodel : "botScheduleCtl_model",
                gettogo : "trading_view",

                params : {
                    trading_type : "optimize",
                    id : id,
                    buy_pair_id : buy_pair_id,
                    strategy_group: strategy_group_no,
                    strategy_name: strategy_name,
                    exchange_name: exchanges_str + ":" + coin_type + fiat_type,
                    coin_type: coin_type + fiat_type,
                    time_frame: time_frame,
                    position: "BUY",
                    price: "0",
                    buy_pair_trigger_price : buy_pair_trigger_price ,
                    operation_type: msg,
                    order_type: order_type,
                    alarm_sent_time: String(new Date(time) / 1000)
                }
        };
        //alert(JSON.stringify(this_data))
        
        axios
            .post(process.env.REACT_APP_TV_SERVER, this_data)
            .then((res) => {

                alert(res.data.msg)
                window.location.reload();
            })
            .catch((err) => console.log(err));
        

    }

    const forceControl = (title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_id) => {
        if (window.confirm(title)) {
            let buy_pair_trigger_price = document.getElementById("buy_pair_trigger_price" + id).value
            if(msg == "trigger" ) {

                if(buy_pair_trigger_price == "" ) {

                    if (window.confirm("Do you want to abort trading date ?\n\n(You set space for trigger price)")) {
                        setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                    }

                }else {
                    setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                }


            }else {

                if(msg == "reset_optimizer" ) {
                    setTrading_optimizer_reset(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                }
                else{

                    if(msg == "terminate_optimizer" ) {
                        setTrading_optimizer_terminate(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                    }else{
                        setTrading(title, msg, exchanges_str , strategy_group_no , strategy_name , coin_type ,time_frame,fiat_type,id,buy_pair_trigger_price,buy_pair_id)
                    }

                   
                }
            }            
        }
    };

    function CPrice({grp, ticker}){
        let result = ""
        //alert(grp)
        //alert(ticker)

        //alert(JSON.stringify(balanceStatus));
        //alert(JSON.stringify(balanceStatus));
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                result = parseFloat(k.c_price).toLocaleString("ko-KR", locale_opt)
            } 
        }) 
        return result
    }
    

    function ChangePrice({sell_price,grp, ticker}){
        let current_price = 0.0
        let result = ""
        //alert(grp)
        //alert(ticker)
        //alert(sell_price)

        //alert(JSON.stringify(balanceStatus));
        //alert(JSON.stringify(balanceStatus));
        balanceStatus.map((k, i) => {
            if (k.grp === grp && k.crypto === ticker) {
                current_price = parseFloat(k.c_price)
            } 
        }) 
        let temp_val = 0.0
        temp_val = current_price - parseFloat(sell_price)
        result = temp_val.toFixed(6).toString()
        return result
    }

    
    useEffect(() => {
        //alert(editButtons);
    }, [editButtons]);
    


    function DemoFunction() {
        
        return (
            <><h1>aaa</h1>
            </>
        );
    }
    

    return (
        <div className="first_div">
            <div className="second_div">
                <div>
                    <h5>Trading Strategy Status</h5>
                </div>
                
                &emsp;
                <table className=""  style={{ textAlign: "center" } } >
                    <thead style={{ background: "BLACK" }}>
                        <tr>
                            <th style={{ width: "3%" }}>STRATEGY</th>
                            <th style={{ width: "4%" }}>TICKER</th>
                            <th style={{ width: "3%" }}>COUNT</th>
                            <th style={{ width: "90%" }}>CONTENT</th>
                        </tr>
                    </thead>
                   
                    <tbody style={{ textAlign: "center" } } >
                        {
                             console.log("==================================================")
                        }
                        {
                        
                            console.log(JSON.stringify(botStatus))
                        }
                    {
                        
                        botStatus.map((bot,a) => (
                            Object.keys(bot).toString()=== exchangesSelect ? (
                                
                                // exchange
                                bot[Object.keys(bot)].map((strategy_group,b) => (

                                    // strategy group , mlc
                                    strategy_group[Object.keys(strategy_group)].map((coins,c) => (

                                        //contents -->  cointents[0]- btc , cointents[1] : {'bot_count': 4, 'bot_content': [{'id)
                                       
                                        Object.entries(coins).map((coin_contents,d) => (

                                            <tr style={{  textAlign: "center" , background: `${Object.keys(strategy_group).toString().toUpperCase() === "MLC" ? "#0070C0" : Object.keys(strategy_group).toString().toUpperCase() === "P2V" ? "#1E8062" : "#7030A0"}` }}>    
                                                <td style={{ width: "3%" }}>{Object.keys(strategy_group).toString().toUpperCase()}</td>
                                                <td style={{ width: "4%" }}>{coin_contents[0].toString().toUpperCase()}</td>
                                                <td style={{ width: "3%" }} >{coin_contents[1]['bot_count']}</td>
                                                <td style={{ width: "90%" }}>
                                                {
                                                     Array.from({length: Number(coin_contents[1]['bot_content'].length)}, (v, i) => i).map((name_2, temp_index) => (
                                                        <>
                                                        <tr>
                                                            <td style={{ width: "5%" }}>BOT#{temp_index+1}</td>
                                                            <td style={{  width : "95%" ,textAlign: "center"}}>
                                                                {coin_contents[1]['bot_content'][temp_index]&&coin_contents[1]['bot_content'][temp_index] ? (
                                                                    <table style={{ width: "100%" }}>
                                                                    <tr>
                                                                    <td style={{ width: "1500px" ,textAlign: "center" }}>
                                                                        {
                                                                            coin_contents[1]['bot_content'][temp_index].sub_trading_data.map((sub_data,d) => (
                                                                               
                                                                                        <div
                                                                                        style={{ 
                                                                                            float : "left" ,
                                                                                            width: "300px" ,
                                                                                            display: "grid",
                                                                                            textAlign: "center",
                                                                                            border : "1px solid blue"
                                                                                        }}>
                                                                                            <span>{sub_data.strategy_name}<span style={{ color: "#FFC000" }}>_{sub_data.time_frame}</span></span>
                                                                                            <span>({sub_data.transaction_time})</span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                {sub_data.percent + "%"} ({sub_data.crypto_qty} {sub_data.coin_type})
                                                                                            </span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                SELL PRICE : {sub_data.current_crypto_price} {sub_data.fiat_type}
                                                                                            </span>
                                                                                            <span>
                                                                                                {" "}
                                                                                                AMOUNT : {sub_data.fiat_qty} {sub_data.fiat_type}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Current Price : <CPrice grp={sub_data.strategy_group_name} ticker={sub_data.coin_type} /> {sub_data.fiat_type}
                                                                                            </span>
                                                                                            <span style={{ color: "#FFC000" }}>
                                                                                                Change  : <ChangePrice sell_price={sub_data.current_crypto_price} grp={sub_data.strategy_group_name} ticker={sub_data.coin_type} /> {sub_data.fiat_type}
                                                                                            </span>

                                                                                            
                                                                                            <div style={{ display: "flex" ,margin: "0em 0em 2em 0em" }}>
                                                                                                {/* trigger price */}
                                                                                                <div style={{margin: "2.5em 0px 0px 0em" , width : "60%" }}>
                                                                                                Trigger Price : <input type="number" style={{ margin: "0px 0px 0px 0px" , width: "50%" }}  id={"buy_pair_trigger_price" + sub_data.id} defaultValue={sub_data.buy_pair_trigger_price} disabled={editButtons[sub_data.id] === "edit" ? "" : "disabled"} onChange={(e) => setInput_trigger_price(e.target.id, e.target.value)}/>
                                                                                                </div>
                                                                                                {/* edit button */}
                                                                                                <div hidden={editButtons[sub_data.id] === "edit" ? true : false}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                                                    <div style={{ width : "100%" }}>
                                                                                                        <button style={{ background: "green", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={(e) => handleEdit(sub_data.id,"edit")} >Edit</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* save button 
                                                                                                {/*
                                                                                                <div hidden={editButtons[sub_data.id] === "edit" ? false : true}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                                                    <div style={{ width : "100%" }}>
                                                                                                        <button style={{ background: "orange", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={(e) => save_trigger_price("Do you want to set trigger price ?" ,sub_data.id) } >Save</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                */}
                                                                                                <div hidden={editButtons[sub_data.id] === "edit" ? false : true}  style={{margin: "2.5em 0px 0px 0em"  , display : "flex" , width : "40%" }}>
                                                                                                    <div style={{ width : "100%" }}>
                                                                                                        <button style={{ background: "orange", color: "white", width: "90px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Save Trigger Price?", "trigger", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name,sub_data.coin_type , sub_data.time_frame, sub_data.fiat_type,sub_data.id,sub_data.buy_pair_id)}>Save</button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                            { business_type == "p_bot_optimize" ? (
                                                                                                <div style={{ display: "flex" }}>

                                                                                                <button hidden={sub_data.exchange_name.toUpperCase() === "MEXC" ? true : false}
                                                                                                        style={{ background: "green", color: "white", width: "110px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Close position?", "close", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name, sub_data.coin_type,sub_data.time_frame, sub_data.fiat_type , sub_data.id, sub_data.buy_pair_id)}>                                                                               
                                                                                                        Close Position
                                                                                                    </button>
                                                                                                    <button 
                                                                                                        style={{ background: "red", color: "white", width: "110px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Terminate position?", "terminate_optimizer", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name,sub_data.coin_type , sub_data.time_frame, sub_data.fiat_type, sub_data.id, sub_data.buy_pair_id)}>
                                                                                                        Terminate
                                                                                                    </button>

                                                                                                    <button 
                                                                                                        style={{ background: "yellow", color: "white", width: "110px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Reset optimizer?", "reset_optimizer", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name,sub_data.coin_type , sub_data.time_frame, sub_data.fiat_type, sub_data.id, sub_data.buy_pair_id)}>
                                                                                                        Reset Optimizer
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                               
                                                                                                <div style={{ display: "flex" }}>
                                                                                                    <button hidden={sub_data.exchange_name.toUpperCase() === "MEXC" ? true : false}
                                                                                                        style={{ background: "green", color: "white", width: "110px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Close position?", "close", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name, sub_data.coin_type,sub_data.time_frame, sub_data.fiat_type , sub_data.id, sub_data.buy_pair_id)}>                                                                               
                                                                                                        Close Position
                                                                                                    </button>
                                                                                                    <button 
                                                                                                        style={{ background: "red", color: "white", width: "110px", margin: "auto" }}
                                                                                                        onClick={() => forceControl("Terminate position?", "terminate", sub_data.exchange_name,sub_data.strategy_group,sub_data.strategy_name,sub_data.coin_type , sub_data.time_frame, sub_data.fiat_type, sub_data.id, sub_data.buy_pair_id)}>
                                                                                                        Terminate
                                                                                                    </button>
                                                                                                </div>
                                                                                            )}
                                                                                            
                                                                                    </div>
                                                                               
                                                                            ) )
                                                                        
                                                                        }
                                                                    </td>
                                                                    </tr>
                                                                    </table>
                                                                ) : (
                                                                    <table className="table_none_border">
                                                                    <tr>
                                                                    <td className="table_none_border">
                                                                        <span>x</span>
                                                                     </td>
                                                                     </tr>
                                                                     </table>

                                                            )
                                                            }
                                                        </td>
                                                        </tr>
                                                        </>
                                                    ))
                                                }
                                                </td>
                                            </tr>
                                        ))//Object.entries(coins).map((coin_contents,d)
                                    )) //strategy_group[Object.keys(strategy_group)].map((coins,c
                                ))
                            ) : null
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BotStatus_new;


